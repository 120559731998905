import Vuex from "vuex";
import Vue from "vue";

Vue.use(Vuex);

export const hospitals = {
  namespaced: true,
  state: () => ({
    hospitals: null,
    hospital: null,
  }),
  getters: {},
  mutations: {
    SET_HOSPITALS: (state, data) => {
      state.hospitals = data;
    },
    SET_HOSPITAL: (state, data) => {
      state.hospital = data;
    },
  },
  actions: {
    fetchHospitals({ commit }) {
      this.$store.dispatch("loading/triggerTableLoader");
      const url = `enums/hospital-services/hospitals`;
      return this.$axios
        .get(url)
        .then((response) => {
          commit("SET_HOSPITALS", response.data.hospitals);
          this.$store.dispatch("loading/detriggerTableLoader");
        })
        .catch((error) => {
          console.log("Error fetching hospitals: " + error);
          this.$store.dispatch("loading/detriggerTableLoader");
        })
    },
    showHospital: function ({ commit }, hospitalId) {
      const url = `enums/hospital-services/hospitals/${hospitalId}`;
      return this.$axios
        .get(url)
        .then((response) => {
          commit("SET_HOSPITAL", response.data.hospital);
        })
        .catch((error) => {
          console.error("Error fetching hospital: " + error);
        });
    },
    createHospital: function ({ dispatch }, payload) {
      this.$store.dispatch("loading/triggerFunctionLoader", "submit");
      const url = `enums/hospital-services/hospitals`;
      return this.$axios
        .post(url, payload)
        .then((response) => {
          dispatch("fetchHospitals");
          this.$store.commit("alerts/SET_SHOW_ALERT", response.data.message);
          this.$store.dispatch("loading/detriggerFunctionLoader", "submit");
        })
        .catch((error) => {
          this.$store.commit("alerts/SET_SHOW_ERROR", error.response.data.message);
          this.$store.dispatch("loading/detriggerFunctionLoader", "submit");
        });
    },
    updateHospital: function ({ dispatch }, { payload, hospitalId }) {
      this.$store.dispatch("loading/triggerFunctionLoader", "submit");
      const url = `enums/hospital-services/hospitals/${hospitalId}`;
      return this.$axios
        .put(url, payload)
        .then((response) => {
          dispatch("fetchHospitals");
          this.$store.commit("alerts/SET_SHOW_ALERT", response.data.message);
          this.$store.dispatch("loading/detriggerFunctionLoader", "submit");
        })
        .catch((error) => {
          this.$store.commit("alerts/SET_SHOW_ERROR", error.response.data.message);
          this.$store.dispatch("loading/detriggerFunctionLoader", "submit");
        });
    },
    deleteHospital: function ({ dispatch }, hospitalId) {
      this.$store.dispatch("loading/triggerFunctionLoader", "delete");
      const url = `enums/hospital-services/hospitals/${hospitalId}`;
      return this.$axios
        .delete(url)
        .then((response) => {
          dispatch("fetchHospitals");
          this.$store.commit("alerts/SET_SHOW_ALERT", response.data.message);
          this.$store.dispatch("loading/detriggerFunctionLoader", "delete");
        })
        .catch((error) => {
          this.$store.commit("alerts/SET_SHOW_ERROR", error.response.data.message);
          this.$store.dispatch("loading/detriggerFunctionLoader", "delete");
        });
    },
  },
};
