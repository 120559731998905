export default [
  {
    path: "/diagnostics",
    name: "diagnostics",
    component: () =>
      import(
        /* webpackChunkName: "diagnostics" */ "@/views/diagnostics/DiagnosticsView.vue"
      ),
    meta: {
      activeIcon: "Diagnostics",
      routeName: "Diagnostics",
    },
  },
  {
    path: "/diagnostics/:id",
    name: "diagnostic-details",
    component: () =>
      import(
        /* webpackChunkName: "diagnostics" */ "@/views/diagnostics/DiagnosticInformationView.vue"
      ),
    props: (route) => ({
      id: String(route.params.id),
    }),
    meta: {
      activeIcon: "Diagnostics",
      routeName: "Diagnostic Information",
    },
  },
  {
    path: "/diagnostics/:id/files/:hospital_service_id",
    name: "diagnostic-files",
    component: () =>
      import(
        /* webpackChunkName: "diagnostics" */ "@/views/diagnostics/DiagnosticFilesView.vue"
      ),
    props: (route) => ({
      id: String(route.params.id),
      hospital_service_id: String(route.params.hospital_service_id),
    }),
    meta: {
      activeIcon: "Diagnostics",
      routeName: "Diagnostic Files",
    },
  },
  {
    path: "/diagnostics/:id/files/:hospital_service_id/viewer",
    name: "diagnostic-files-view",
    component: () =>
      import(/* webpackChunkName: "dialysis" */ "@/views/FileView.vue"),
    props: true,
    meta: {
      activeIcon: "Laboratories",
      routeName: "Laboratories",
    },
  },
];
