import Vuex from "vuex";
import Vue from "vue";

Vue.use(Vuex);

export const specialties = {
  namespaced: true,
  state: () => ({
    specialties: null,
    specialty: null,
    tableLoader: false,
  }),
  getters: {},
  mutations: {
    SET_SPECIALTIES: (state, data) => (state.specialties = data),
    SET_SPECIALTY: (state, data) => (state.specialty = data),
    SET_TABLE_LOADER: (state, data) => (state.tableLoader = data),
  },
  actions: {
    fetchSpecialties: function ({ commit }) {
      commit("SET_TABLE_LOADER", true);
      const url = `enums/hospital-services/specialties`;
      return this.$axios
        .get(url)
        .then((response) => {
          commit("SET_SPECIALTIES", response.data.specialty);
          commit("SET_TABLE_LOADER", false);
        })
        .catch((error) => {
          console.error("Error fetching specialties: ", error);
          commit("SET_TABLE_LOADER", false);
        });
    },
    showSpecialty: function ({ commit }, specialtyId) {
      const url = `enums/hospital-services/specialties/${specialtyId}`;
      return this.$axios
        .get(url)
        .then((response) => {
          commit("SET_SPECIALTY", response.data.specialty);
        })
        .catch((error) => {
          console.error("Error fetching specialty: ", error);
        });
    },
    createSpecialty: function ({ dispatch }, payload) {
      this.$store.dispatch("loading/triggerFunctionLoader", "submit");
      const url = `enums/hospital-services/specialties`;
      return this.$axios
        .post(url, payload)
        .then((response) => {
          dispatch("fetchSpecialties");
          this.$store.commit("alerts/SET_SHOW_ALERT", response.data.message);
          this.$store.dispatch("loading/detriggerFunctionLoader", "submit");
        })
        .catch((error) => {
          console.error("Error creating specialty: ", error);
          this.$store.commit(
            "alerts/SET_SHOW_ERROR",
            error.response.data.message
          );
          this.$store.dispatch("loading/detriggerFunctionLoader", "submit");
        });
    },
    updateSpecialty: function ({ dispatch }, { specialtyId, payload }) {
      this.$store.dispatch("loading/triggerFunctionLoader", "submit");
      const url = `enums/hospital-services/specialties/${specialtyId}`;
      return this.$axios
        .put(url, payload)
        .then((response) => {
          dispatch("fetchSpecialties");
          this.$store.commit("alerts/SET_SHOW_ALERT", response.data.message);
          this.$store.dispatch("loading/detriggerFunctionLoader", "submit");
        })
        .catch((error) => {
          console.error("Error updating specialty: ", error);
          this.$store.commit(
            "alerts/SET_SHOW_ERROR",
            error.response.data.message
          );
          this.$store.dispatch("loading/detriggerFunctionLoader", "submit");
        });
    },
    deleteSpecialty: function ({ dispatch }, specialtyId) {
      this.$store.dispatch("loading/triggerFunctionLoader", "delete");
      const url = `enums/hospital-services/specialties/${specialtyId}`;
      return this.$axios
        .delete(url)
        .then((response) => {
          dispatch("fetchSpecialties");
          this.$store.commit("alerts/SET_SHOW_ALERT", response.data.message);
          this.$store.dispatch("loading/detriggerFunctionLoader", "delete");
        })
        .catch((error) => {
          console.error("Error updating specialty: ", error);
          this.$store.commit(
            "alerts/SET_SHOW_ERROR",
            error.response.data.message
          );
          this.$store.dispatch("loading/detriggerFunctionLoader", "delete");
        });
    },
  },
};
