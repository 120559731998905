// Authentication Object
import { auth } from "@/utils/auth";

// Route Modules
import doctor from "./doctor";
import citizens from "./citizens";
import hospitalServices from "./hospital-services";
import enums from "./enums";
import dialysis from "./dialysis";
import diagnostics from "./diagnostics";
import laboratories from "./laboratories";
import footer from "./footer";

const publicRoutes = [
  "reroute",
  "public-file-view",
  "Minalin Dialysis Center",
  "login",
  ...footer.map(({ name }) => name),
];

// Map roles to their allowed routes
const roleRoutes = {
  DOCTOR: [...doctor.map(({ name }) => name)],
  ADMIN: [
    ...citizens.map(({ name }) => name),
    ...hospitalServices.map(({ name }) => name),
    ...enums.map(({ name }) => name),
  ],
  ROOT: [
    ...citizens.map(({ name }) => name),
    ...hospitalServices.map(({ name }) => name),
    ...enums.map(({ name }) => name),
    "dialysis-queuing",
  ],
  ENCODER: [
    "citizens",
    "register-citizen",
    "edit-citizen",
    "citizen-details",
    ...hospitalServices.map(({ name }) => name),
  ],
  VIP_ENCODER: [
    "citizens",
    "register-citizen",
    "edit-citizen",
    "citizen-details",
    ...hospitalServices.map(({ name }) => name),
  ],
  DIALYSIS_ENCODER: [...dialysis.map(({ name }) => name)],
  DIAGNOSTIC_ENCODER: [...diagnostics.map(({ name }) => name)],
  LABORATORY_ENCODER: [...laboratories.map(({ name }) => name)],
};

// Common routes accessible to all roles
const commonRoutes = [
  "management",
  "dashboard",
  ...publicRoutes,
  ...footer.map(({ name }) => name),
];

export function checkLoggedIn(to, from, next) {
  const isLoggedIn = auth.user();
  const userRole = auth.role();

  if (!isLoggedIn && !publicRoutes.includes(to.name)) {
    next({ name: "login" });
    return;
  }

  if (isLoggedIn && to.name === "login") {
    next({ name: "dashboard" });
    return;
  }

  const allowedRoutes = [...(roleRoutes[userRole] || []), ...commonRoutes];

  if (!allowedRoutes.includes(to.name)) {
    next({ name: "dashboard" });
    return;
  }

  next();
}
