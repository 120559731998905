export default [
  {
    path: "/dialysis-items",
    name: "dialysis-items",
    component: () =>
      import(
        /* webpackChunkName: "admin-enums" */ "@/views/enums/DialysisItemsView.vue"
      ),
    props: true,
    meta: {
      activeIcon: "Dialysis Items",
      routeName: "Dialysis Items",
    },
  },
  {
    path: "/document-types",
    name: "document-types",
    component: () =>
      import(
        /* webpackChunkName: "admin-enums" */ "@/views/enums/DocumentTypesView.vue"
      ),
    props: true,
    meta: {
      activeIcon: "Documents",
      routeName: "Document Types",
    },
  },
  {
    path: "/features",
    name: "features",
    component: () =>
      import(
        /* webpackChunkName: "admin-enums" */ "@/views/enums/FeaturesView.vue"
      ),
    props: true,
    meta: {
      activeIcon: "Features",
      routeName: "Features",
    },
  },
  {
    path: "/service-types",
    name: "service-types",
    component: () =>
      import(
        /* webpackChunkName: "admin-enums" */ "@/views/enums/ServiceTypesView.vue"
      ),
    props: true,
    meta: {
      activeIcon: "Service Types",
      routeName: "Service Types",
    },
  },
  {
    path: "/medical-sites",
    name: "medical-sites",
    component: () =>
      import(
        /* webpackChunkName: "admin-enums" */ "@/views/enums/MedicalSitesView.vue"
      ),
    props: true,
    meta: {
      activeIcon: "Medical Sites",
      routeName: "Medical Sites",
    },
  },
];
