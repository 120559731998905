export default [
  {
    path: "/consultations",
    name: "consultations",
    component: () =>
      import(
        /* webpackChunkName: "doctor-consultations" */ "@/views/consultations/doctor/RequestedConsultationsView.vue"
      ),
    props: true,
    meta: {
      activeIcon: "Consultations",
      routeName: "Consultations",
    },
  },
  {
    path: "/consultations/:consultation_id/view/:hospital_service_id",
    name: "consultation-view",
    component: () =>
      import(
        /* webpackChunkName: "doctor-consultations" */ "@/views/consultations/ConsultationInformationView.vue"
      ),
    props: (route) => ({
      consultation_id: String(route.params.consultation_id),
      hospital_service_id: String(route.params.hospital_service_id),
    }),
    meta: {
      activeIcon: "Consultations",
      routeName: "Consultation Details",
    },
  },
  {
    path: "/consultations/:consultation_id/view/:hospital_service_id/form",
    name: "add-consultation-form",
    component: () =>
      import(
        /* webpackChunkName: "doctor-consultations" */ "@/views/consultations/doctor/AddConsultationFormView.vue"
      ),
    props: true,
    meta: {
      activeIcon: "Consultations",
      routeName: "Add Consultation Form",
    },
  },
  {
    path: "/consultations/:consultation_id/view/:hospital_service_id/form/2",
    name: "consultation-form-continuation",
    component: () =>
      import(
        /* webpackChunkName: "doctor-consultations" */ "@/views/consultations/form/ConsultationFormContinuation.vue"
      ),
    props: true,
    meta: {
      activeIcon: "Consultations",
      routeName: "Add Consultation Form",
    },
  },
  {
    path: "/consultations/:consultation_id/view/:hospital_service_id/edit-form/:consultation_form_id",
    name: "doctor-edit-consultation-form",
    component: () =>
      import(
        /* webpackChunkName: "doctor-consultations" */ "@/views/consultations/form/EditConsultationFormView.vue"
      ),
    props: (route) => ({
      consultation_id: String(route.params.consultation_id),
      hospital_service_id: String(route.params.hospital_service_id),
      consultation_form_id: String(route.params.consultation_form_id),
      role: "DOCTOR",
    }),
    meta: { activeIcon: "Consultations", routeName: "Edit Consultation Form" },
  },
  {
    path: "/consultations/:consultation_id/view/:hospital_service_id/edit-form/:consultation_form_id/continuation",
    name: "doctor-edit-consultation-form-continuation",
    component: () =>
      import(
        /* webpackChunkName: "doctor-consultations" */ "@/views/consultations/form/ConsultationFormContinuation.vue"
      ),
    props: (route) => ({
      consultation_id: String(route.params.consultation_id),
      hospital_service_id: String(route.params.hospital_service_id),
      consultation_form_id: String(route.params.consultation_form_id),
      role: "DOCTOR",
    }),
    meta: { activeIcon: "Consultations", routeName: "Edit Consultation Form" },
  },
  {
    path: "/consultations/:consultation_id/view/:hospital_service_id/files",
    name: "consultation-files",
    component: () =>
      import(
        /* webpackChunkName: "doctor-consultations" */ "@/views/consultations/ConsultationFilesView.vue"
      ),
    props: (route) => ({
      hospital_service_id: String(route.params.hospital_service_id),
    }),
    meta: {
      activeIcon: "Consultations",
      routeName: "Consultation Files",
    },
  },
  {
    path: "/consultations/:consultation_id/view/:hospital_service_id/files/viewer",
    name: "consultation-files-view",
    component: () =>
      import(
        /* webpackChunkName: "doctor-consultations" */ "@/views/FileView.vue"
      ),
    props: true,
    meta: {
      activeIcon: "Consultations",
      routeName: "Consultation Files",
    },
  },
];
