<template>
  <v-footer app inset padless id="footer">
    <v-row justify="space-between" align="center">
      <v-col cols="auto">
        <div class="d-flex align-center" style="gap: 8px">
          <div>
            <v-img eager height="55" width="55" contain :src="imageUrl" />
          </div>
          <div class="grey--text text--darken-3">
            <div
              class="line-height text-subtitle-1 text-uppercase font-weight-bold"
            >
              SPCF ICTDU
            </div>
            <div class="line-height text-caption grey--text text--darken-1">
              &copy; 2023 All Rights Reserved
            </div>
          </div>
        </div>
      </v-col>
      <v-col cols="auto" class="px-4">
        <v-btn
          icon
          color="primary"
          large
          target="_blank"
          href="/developer/about-us"
        >
          <v-icon>mdi-arrow-top-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: "FooterComponent",
  data: () => ({
    imageUrl: require("@/assets/logo/ictdu-logo.png"),
  }),
};
</script>

<style scoped>
#footer {
  background: #fff;
  border: 1px solid #e0e0e0;
  max-width: 100vw;
  padding: 8px;
}

.line-height {
  line-height: 1.5;
}
</style>
