export default [
  {
    path: "/citizens",
    name: "citizens",
    component: () =>
      import(/* webpackChunkName: "citizens" */ "@/views/CitizensView.vue"),
    meta: { activeIcon: "Citizens", routeName: "Citizens" },
  },
  {
    path: "/citizens/register",
    name: "register-citizen",
    component: () =>
      import(/* webpackChunkName: "citizens" */ "@/views/RegisterView.vue"),
    meta: { activeIcon: "Citizens", routeName: "Register" },
  },
  {
    path: "/citizens/:id/edit",
    name: "edit-citizen",
    component: () =>
      import(/* webpackChunkName: "citizens" */ "@/views/EditView.vue"),
    props: true,
    meta: {
      activeIcon: "Citizens",
      routeName: "Edit Citizen",
    },
  },
  {
    path: "/citizens/:id",
    name: "citizen-details",
    component: () =>
      import(
        /* webpackChunkName: "citizens" */ "@/views/CitizenDetailsView.vue"
      ),
    props: (route) => ({ id: String(route.params.id) }),
    meta: {
      activeIcon: "Citizens",
      routeName: "Citizen Details",
    },
  },
  {
    path: "/citizens-consultations",
    name: "citizens-consultations",
    component: () =>
      import(
        /* webpackChunkName: "citizen-admin" */ "@/views/consultations/admin/CompletedConsultationsView.vue"
      ),
    props: true,
    meta: {
      activeIcon: "Consultations",
      routeName: "Citizen Consultations",
    },
  },
  {
    path: "/citizens-consultations/:consultation_id/view/:hospital_service_id",
    name: "citizens-consultations-view",
    component: () =>
      import(
        /* webpackChunkName: "citizen-admin" */ "@/views/consultations/ConsultationInformationView.vue"
      ),
    props: (route) => ({
      consultation_id: String(route.params.consultation_id),
      hospital_service_id: String(route.params.hospital_service_id),
    }),
    meta: {
      activeIcon: "Consultations",
      routeName: "Citizen Consultation Details",
    },
  },
  {
    path: "/citizens-consultations/:consultation_id/view/:hospital_service_id/form/:consultation_form_id",
    name: "edit-consultation-form",
    component: () =>
      import(
        /* webpackChunkName: "citizen-admin" */ "@/views/consultations/form/EditConsultationFormView.vue"
      ),
    props: (route) => ({
      consultation_id: String(route.params.consultation_id),
      hospital_service_id: String(route.params.hospital_service_id),
      consultation_form_id: String(route.params.consultation_form_id),
      role: "ADMIN",
    }),
    meta: {
      activeIcon: "Consultations",
      routeName: "Edit Consultation Form",
    },
  },
  {
    path: "/citizens-consultations/:consultation_id/view/:hospital_service_id/form/:consultation_form_id/continuation",
    name: "edit-consultation-form-continuation",
    component: () =>
      import(
        /* webpackChunkName: "citizen-admin" */ "@/views/consultations/form/ConsultationFormContinuation.vue"
      ),
    props: (route) => ({
      consultation_id: String(route.params.consultation_id),
      hospital_service_id: String(route.params.hospital_service_id),
      consultation_form_id: String(route.params.consultation_form_id),
      role: "ADMIN",
    }),
    meta: {
      activeIcon: "Consultations",
      routeName: "Edit Consultation Form",
    },
  },
];
