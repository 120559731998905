import Vuex from "vuex";
import Vue from "vue";

Vue.use(Vuex);

export const file_types = {
  namespaced: true,
  state: () => ({
    file_types: null,
  }),
  getters: {},
  mutations: {
    SET_FILE_TYPES(state, data) {
      state.file_types = data;
    },
  },
  actions: {
    fetchFileTypes({ commit }) {
      this.$store.dispatch("loading/triggerTableLoader");
      const url = `enums/hospital-services/files/document-types`;
      return this.$axios
        .get(url)
        .then((response) => {
          const file_types = response.data.document_types;
          commit("SET_FILE_TYPES", file_types);
          this.$store.dispatch("loading/detriggerTableLoader");
        })
        .catch((error) => {
          console.error("Error Fetching File Types Enum: ", error);
          this.$store.dispatch("loading/detriggerTableLoader");
        });
    },
  },
};

// Root or Admin Actions
export const file_types_actions = {
  namespaced: true,
  state: () => ({}),
  getters: {},
  mutations: {},
  actions: {
    createFileType(_, data) {
      this.$store.dispatch("loading/triggerFunctionLoader", "submit");
      const url = `enums/hospital-services/files/document-types`;
      return this.$axios
        .post(url, data)
        .then((response) => {
          this.$store.dispatch("file_types/fetchFileTypes");
          this.$store.commit("alerts/SET_SHOW_ALERT", response.data.message);
          this.$store.dispatch("loading/detriggerFunctionLoader", "submit");
        })
        .catch((error) => {
          console.error("Error Adding File Type: ", error);
          this.$store.commit(
            "alerts/SET_SHOW_ERROR",
            error.response.data.message
          );
          this.$store.dispatch("loading/detriggerFunctionLoader", "submit");
        });
    },
    updateFileType(_, { id, payload }) {
      this.$store.dispatch("loading/triggerFunctionLoader", "submit");
      const url = `enums/hospital-services/files/document-types/${id}`;

      return this.$axios
        .put(url, payload)
        .then((response) => {
          this.$store.dispatch("loading/detriggerFunctionLoader", "submit");
          this.$store.dispatch("file_types/fetchFileTypes");
          this.$store.commit("alerts/SET_SHOW_ALERT", response.data.message);
        })
        .catch((error) => {
          console.error("Error Adding File Type: ", error);
          this.$store.dispatch("loading/detriggerFunctionLoader", "submit");
          this.$store.commit(
            "alerts/SET_SHOW_ERROR",
            error.response.data.message
          );
        });
    },
    deleteFileType(_, id) {
      this.$store.dispatch("loading/triggerFunctionLoader", "delete");
      const url = `enums/hospital-services/files/document-types/${id}`;
      return this.$axios
        .delete(url)
        .then((response) => {
          this.$store.dispatch("file_types/fetchFileTypes");
          this.$store.commit("alerts/SET_SHOW_ALERT", response.data.message);
          this.$store.dispatch("loading/detriggerFunctionLoader", "delete");
        })
        .catch((error) => {
          console.error("Error Deleting File Type: ", error);
          this.$store.commit(
            "alerts/SET_SHOW_ERROR",
            error.response.data.message
          );
          this.$store.dispatch("loading/detriggerFunctionLoader", "delete");
        });
    },
  },
};
