export default [
  {
    path: "/laboratories",
    name: "laboratories",
    component: () =>
      import(
        /* webpackChunkName: "laboratories" */ "@/views/laboratories/LaboratoriesView.vue"
      ),
    meta: {
      activeIcon: "Laboratories",
      routeName: "Laboratories",
    },
  },
  {
    path: "/laboratories/:id",
    name: "laboratory-details",
    component: () =>
      import(
        /* webpackChunkName: "laboratories" */ "@/views/laboratories/LaboratoryDetailsView.vue"
      ),
      props: (route) => ({
        id: String(route.params.id),
      }),
    meta: {
      activeIcon: "Laboratories",
      routeName: "Laboratories",
    },
  },
  {
    path: "/laboratories/:id/files/:hospital_service_id",
    name: "laboratory-files",
    component: () =>
      import(
        /* webpackChunkName: "laboratories" */ "@/views/laboratories/LaboratoryFilesView.vue"
      ),
      props: (route) => ({
        id: String(route.params.id),
        hospital_service_id: String(route.params.hospital_service_id)
      }),
    meta: {
      activeIcon: "Laboratories",
      routeName: "Laboratories",
    },
  },
  {
    path: "/laboratories/:id/files/:hospital_service_id/viewer",
    name: "laboratory-files-view",
    component: () =>
      import(/* webpackChunkName: "dialysis" */ "@/views/FileView.vue"),
    props: true,
    meta: {
      activeIcon: "Laboratories",
      routeName: "Laboratories",
    },
  },
];
