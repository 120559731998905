<template>
  <v-alert
    transition="scroll-y-transition"
    v-if="alert"
    :type="alert.type"
    dismissible
  >
    {{ alert.message }}
  </v-alert>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "AlertComponent",
  computed: {
    ...mapState("alerts", ["alert"]),
  },
};
</script>

<style scoped></style>
