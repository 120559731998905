import Vuex from "vuex";
import Vue from "vue";

Vue.use(Vuex);

export const diagnostics = {
  namespaced: true,
  state: () => ({
    diagnostics: null,
    diagnostic: null,
    tableLoader: false,
  }),
  getters: {},
  mutations: {
    SET_DIAGNOSTICS: (state, data) => (state.diagnostics = data),
    SET_DIAGNOSTIC: (state, data) => (state.diagnostic = data),
    SET_TABLE_LOADER: (state, data) => (state.tableLoader = data),
  },
  actions: {
    fetchDiagnostics: function ({ commit }) {
      commit("SET_TABLE_LOADER", true);
      const url = `enums/hospital-services/diagnostic-types`;
      return this.$axios
        .get(url)
        .then((response) => {
          commit("SET_DIAGNOSTICS", response.data.diagnosticType);
          commit("SET_TABLE_LOADER", false);
        })
        .catch((error) => {
          console.error("Error fetching diagnostics: ", error);
          commit("SET_TABLE_LOADER", false);
        });
    },
    showDiagnostic: function ({ commit }, diagnosticId) {
      const url = `enums/hospital-services/diagnostic-types/${diagnosticId}`;
      return this.$axios
        .get(url)
        .then((response) => {
          commit("SET_DIAGNOSTIC", response.data.diagnosticType);
        })
        .catch((error) => {
          console.error("Error fetching diagnostic: ", error);
        });
    },
    createDiagnostic: function ({ dispatch }, payload) {
      this.$store.dispatch("loading/triggerFunctionLoader", "submit");
      const url = `enums/hospital-services/diagnostic-types`;
      return this.$axios
        .post(url, payload)
        .then((response) => {
          dispatch("fetchDiagnostics");
          this.$store.commit("alerts/SET_SHOW_ALERT", response.data.message);
          this.$store.dispatch("loading/detriggerFunctionLoader", "submit");
        })
        .catch((error) => {
          console.error("Error creating diagnostic: ", error);
          this.$store.commit(
            "alerts/SET_SHOW_ERROR",
            error.response.data.message
          );
          this.$store.dispatch("loading/detriggerFunctionLoader", "submit");
        });
    },
    updateDiagnostic: function ({ dispatch }, { diagnosticId, payload }) {
      this.$store.dispatch("loading/triggerFunctionLoader", "submit");
      const url = `enums/hospital-services/diagnostic-types/${diagnosticId}`;
      return this.$axios
        .put(url, payload)
        .then((response) => {
          dispatch("fetchDiagnostics");
          this.$store.commit("alerts/SET_SHOW_ALERT", response.data.message);
          this.$store.dispatch("loading/detriggerFunctionLoader", "submit");
        })
        .catch((error) => {
          console.error("Error updating diagnostic: ", error);
          this.$store.commit(
            "alerts/SET_SHOW_ERROR",
            error.response.data.message
          );
          this.$store.dispatch("loading/detriggerFunctionLoader", "submit");
        });
    },
    deleteDiagnostic: function ({ dispatch }, diagnosticId) {
      this.$store.dispatch("loading/triggerFunctionLoader", "delete");
      const url = `enums/hospital-services/diagnostic-types/${diagnosticId}`;
      return this.$axios
        .delete(url)
        .then((response) => {
          dispatch("fetchDiagnostics");
          this.$store.commit("alerts/SET_SHOW_ALERT", response.data.message);
          this.$store.dispatch("loading/detriggerFunctionLoader", "delete");
        })
        .catch((error) => {
          console.error("Error updating diagnostic: ", error);
          this.$store.commit(
            "alerts/SET_SHOW_ERROR",
            error.response.data.message
          );
          this.$store.dispatch("loading/detriggerFunctionLoader", "delete");
        });
    },
  },
};
