import Vue from "vue";
import Vuex from "vuex";
import { functions } from "@/utils/functions";

Vue.use(Vuex);

export const laboratories = {
  namespaced: true,
  state: () => ({
    laboratories: null,
    laboratory: null,
  }),
  getters: {},
  mutations: {
    SET_LABORATORIES(state, laboratories) {
      state.laboratories = laboratories;
    },
    SET_LABORATORY(state, laboratory) {
      state.laboratory = laboratory;
    },
  },
  actions: {
    fetchLaboratories({ commit }, queryParams = {}) {
      this.$store.dispatch("loading/triggerTableLoader");
      const url = `encoder/laboratories`;
      const query = functions.query(url, queryParams);

      return this.$axios
        .get(query)
        .then((response) => {
          commit("SET_LABORATORIES", response.data);
          this.$store.dispatch("loading/detriggerTableLoader");
        })
        .catch((error) => {
          console.error("Error fetching laboratories: ", error);
          this.$store.dispatch("loading/detriggerTableLoader");
        });
    },
    fetchLaboratoryDetails({ commit }, id) {
      const url = `encoder/laboratories/${id}`;

      return this.$axios
        .get(url)
        .then((response) => {
          commit("SET_LABORATORY", response.data.data);
        })
        .catch((error) => {
          console.error("Error fetching laboratory information: ", error);
        });
    },
    toggleLaboratoryStatus({ dispatch }, { hospitalID, laboratoryId, status }) {
      const url = `hospital-services/${hospitalID}/toggle-status`;

      return this.$axios
        .patch(url, status)
        .then((response) => {
          this.$store.commit("alerts/SET_SHOW_ALERT", response.data.message);
          dispatch("fetchLaboratoryDetails", laboratoryId);
        })
        .catch((error) => {
          console.error("Error patching laboratory service status: ", error);
          this.$store.commit(
            "alerts/SET_SHOW_ERROR",
            error.response.data.message
          );
        });
    },
  },
};
