import Vuex from "vuex";
import Vue from "vue";

Vue.use(Vuex);

export const loading = {
  namespaced: true,
  state: () => ({
    // loader: {
    //   table: false,
    //   function: false,
    // },
    tableLoader: false,
    functionLoader: {
      submit: false,
      delete: false,
    },
  }),
  getters: {},
  mutations: {
    SET_TABLE_LOADER: (state, data) => (state.tableLoader = data),
    SET_FUNCTION_LOADER: (state, data) => (state.functionLoader = { ...data }),
  },
  actions: {
    triggerTableLoader: function ({ commit }) {
      commit("SET_TABLE_LOADER", true);
    },
    detriggerTableLoader: function ({ commit }) {
      commit("SET_TABLE_LOADER", false);
    },
    triggerFunctionLoader: function ({ commit }, type) {
      commit("SET_FUNCTION_LOADER", { [type]: true });
    },
    detriggerFunctionLoader: function ({ commit }, type) {
      commit("SET_FUNCTION_LOADER", { [type]: false });
    },
  },
};
