export default [
  {
    path: "/developer/about-us",
    name: "developer-about-us",
    import: () =>
      import(
        /* webpackChunkName: "public" */ "@/views/footer/DeveloperAboutView.vue"
      ),
    meta: {
      routeName: "Developer About Us",
    },
  },
];
