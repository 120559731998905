import Vuex from "vuex";
import Vue from "vue";
import { functions } from "@/utils/functions";

Vue.use(Vuex);

export const publicData = {
  namespaced: true,
  state: () => ({
    publicData: null,
    publicServices: null,

    // Files
    publicFiles: null,
    publicFile: null,
  }),
  getters: {},
  mutations: {
    SET_PUBLIC_DATA(state, publicData) {
      state.publicData = publicData;
    },
    SET_PUBLIC_SERVICES(state, publicServices) {
      state.publicServices = publicServices;
    },
    SET_PUBLIC_FILES(state, files) {
      state.publicFiles = files;
    },
    SET_PUBLIC_FILE(state, { file, file_type }) {
      state.publicFile = {
        file: file,
        file_type: file_type,
      };
    },
  },
  actions: {
    fetchPublicRecord({ commit }, hub_registrant_id) {
      const url = `public-citizen/${hub_registrant_id}`;
      return this.$axios
        .get(url)
        .then((response) => {
          commit("SET_PUBLIC_DATA", response.data);
        })
        .catch((error) => {
          console.error("Error fetching public data:", error);
        });
    },
    fetchPublicServicesById({ commit }, { id, queryParams = {} }) {
      const url = `citizens/${id}/public/hospital-services`;
      const query = functions.query(url, queryParams);
      return this.$axios
        .get(query)
        .then((response) => {
          commit("SET_PUBLIC_SERVICES", response.data.hospitalServices);
        })
        .catch((error) => {
          console.error("Error requesting public services: ", error);
        });
    },
    fetchPublicFiles({ commit }, { citizen_id, queryParams = {} }) {
      const url = `citizens/${citizen_id}/public/hospital-services/files`;
      const query = functions.query(url, queryParams);
      return this.$axios
        .get(query)
        .then((response) => {
          commit("SET_PUBLIC_FILES", response.data.data);
        })
        .catch((error) => {
          console.error("Error fetching files: ", error);
        });
    },
    resetPublicFiles({ commit }) {
      commit("SET_PUBLIC_FILES", []);
    },
    fetchPublicFile(
      { commit },
      { citizen_id, hospital_service_id, hospital_service_file_id }
    ) {
      const url = `citizens/${citizen_id}/public/hospital-services/${hospital_service_id}/files/${hospital_service_file_id}`;
      return this.$axios.get(url, { responseType: "blob" }).then((response) => {
        const contentType = response.headers["content-type"];
        let file_type = null;

        // Check the Content-Type to determine the type of content
        if (contentType.startsWith("image/")) {
          file_type = "Image";
        } else if (contentType === "application/pdf") {
          file_type = "PDF";
        } else {
          console.log("Cannot Handle These File Types");
        }

        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.onload = (event) => {
            const file = event.target.result;
            commit("SET_PUBLIC_FILE", { file, file_type });
            resolve();
          };
          reader.onerror = (error) => {
            console.error("Error Loading Consultation File: ", error);
            commit("SET_PUBLIC_FILE", null);
            resolve();
          };

          reader.readAsDataURL(response.data);
        });
      });
    },
  },
};
