import Vuex from "vuex";
import Vue from "vue";
import { functions } from "@/utils/functions.js";

Vue.use(Vuex);

// Query Parameters
// -   `country_id`: The ID of the selected country.
// -   `region_code`: The code of the selected region within the country.
// -   `province_code`: The code of the selected province within the region.
// -   `city_code`: The code of the selected city within the province.

export const philippines = {
  namespaced: true,
  state: () => ({
    countries: [],
    regions: [],
    provinces: [],
    cities: [],
    barangays: [],
  }),
  getters: {
    getBarangays: (state) => state.barangays,
    getCities: (state) => state.cities,
    getProvinces: (state) => state.provinces,
    getRegions: (state) => state.regions,
    getCountries: (state) => state.countries,
  },
  mutations: {
    SET_COUNTRIES(state, countries) {
      state.countries = countries;
    },
    SET_REGIONS(state, regions) {
      state.regions = regions;
    },
    SET_PROVINCES(state, provinces) {
      state.provinces = provinces;
    },
    SET_CITIES(state, cities) {
      state.cities = cities;
    },
    SET_BARANGAYS(state, barangays) {
      state.barangays = barangays;
    },
  },
  actions: {
    fetchPhilippines({ commit }, queryParams) {
      const url = `enums/philippines`;
      const query = functions.query(url, queryParams);

      return this.$axios
        .get(query)
        .then((response) => {
          const philippines = response.data;
          commit("SET_COUNTRIES", philippines.countries);
          commit("SET_REGIONS", philippines.regions);
          commit("SET_PROVINCES", philippines.provinces);
          commit("SET_CITIES", philippines.cities);
          commit("SET_BARANGAYS", philippines.barangays);
        })
        .catch((error) => {
          console.error("Error fetching Philippines", error);
        });
    },
    fetchCountries({ commit }) {
      const url = `enums/philippines`;
      return this.$axios
        .get(url)
        .then((response) => {
          const countries = response.data.countries;
          commit("SET_COUNTRIES", countries);
        })
        .catch((error) => {
          console.error("Error fetching countries", error);
        });
    },
    fetchRegions({ commit }, queryParams) {
      const url = `enums/philippines`;
      const query = functions.query(url, queryParams);

      return this.$axios
        .get(query)
        .then((response) => {
          const regions = response.data.regions;
          commit("SET_REGIONS", regions);
        })
        .catch((error) => {
          console.error("Error fetching regions", error);
        });
    },
    fetchProvinces({ commit }, queryParams) {
      const url = `enums/philippines`;
      const query = functions.query(url, queryParams);

      return this.$axios
        .get(query)
        .then((response) => {
          const provinces = response.data.provinces;
          commit("SET_PROVINCES", provinces);
        })
        .catch((error) => {
          console.error("Error fetching provinces", error);
        });
    },
    fetchCities({ commit }, queryParams) {
      const url = `enums/philippines`;
      const query = functions.query(url, queryParams);

      return this.$axios
        .get(query)
        .then((response) => {
          const cities = response.data.cities;
          commit("SET_CITIES", cities);
        })
        .catch((error) => {
          console.error("Error fetching cities", error);
        });
    },
    fetchBarangays({ commit }, queryParams) {
      const url = `enums/philippines`;
      const query = functions.query(url, queryParams);

      return this.$axios
        .get(query)
        .then((response) => {
          const barangays = response.data.barangays;
          commit("SET_BARANGAYS", barangays);
        })
        .catch((error) => {
          console.error("Error fetching barangays", error);
        });
    },
  },
};
