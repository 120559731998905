import Vuex from "vuex";
import Vue from "vue";

Vue.use(Vuex);

export const consultation_form = {
  namespaced: true,
  state: () => ({
    consultation_form_index: null,
    consultation_form: null,
  }),
  getters: {},
  mutations: {
    SET_CONSULTATION_FORM_INDEX: (state, data) =>
      (state.consultation_form_index = data),
    SET_CONSULTATION_FORM: (state, data) => (state.consultation_form = data),
  },
  actions: {
    // ADD METHODS
    addConsultationFormForDoctor: function (_, { consultation_id, data }) {
      this.$store.dispatch("loading/triggerFunctionLoader", "submit");
      const url = `doctors/consultations/${consultation_id}/consultation-forms`;
      return this.$axios
        .post(url, data)
        .then((response) => {
          this.$store.commit("alerts/SET_SHOW_ALERT", response.data.message);
          this.$store.dispatch("loading/detriggerFunctionLoader", "submit");
        })
        .catch((error) => {
          console.log("Error Adding Consultation by Doctor", error);
          this.$store.commit(
            "alerts/SET_SHOW_ERROR",
            error.response.data.message
          );
          this.$store.dispatch("loading/detriggerFunctionLoader", "submit");
        });
    },

    // UPDATE METHODS
    updateConsultationFormForDoctor: function (
      _,
      { consultation_id, consultation_form_id, data }
    ) {
      this.$store.dispatch("loading/triggerFunctionLoader", "submit");
      const url = `doctors/consultations/${consultation_id}/consultation-forms/${consultation_form_id}`;
      return this.$axios
        .put(url, data)
        .then((response) => {
          this.$store.commit("alerts/SET_SHOW_ALERT", response.data.message);
          this.$store.dispatch("loading/detriggerFunctionLoader", "submit");
        })
        .catch((error) => {
          console.error("Error Updating Consultation Form: ", error);
          this.$store.commit(
            "alerts/SET_SHOW_ERROR",
            error.response.data.message
          );
          this.$store.dispatch("loading/detriggerFunctionLoader", "submit");
        });
    },
    updateConsultationFormForAdmin: function (
      _,
      { consultation_id, consultation_form_id, data }
    ) {
      this.$store.dispatch("loading/triggerFunctionLoader", "submit");
      const url = `admin/consultations/${consultation_id}/consultation-forms/${consultation_form_id}`;
      return this.$axios
        .put(url, data)
        .then((response) => {
          this.$store.commit("alerts/SET_SHOW_ALERT", response.data.message);
          this.$store.dispatch("loading/detriggerFunctionLoader", "submit");
        })
        .catch((error) => {
          console.error("Error updating Consultation Form", error);
          this.$store.commit(
            "alerts/SET_SHOW_ERROR",
            error.response.data.message
          );
          this.$store.dispatch("loading/detriggerFunctionLoader", "submit");
        });
    },

    // DELETE METHODS
    deleteConsultationFormForAdmin: function (
      _,
      { consultation_id, consultation_form_id }
    ) {
      this.$store.dispatch("loading/triggerFunctionLoader", "delete");
      const url = `admin/consultations/${consultation_id}/consultation-forms/${consultation_form_id}`;
      return this.$axios
        .delete(url)
        .then((response) => {
          this.$store.commit("alerts/SET_SHOW_ALERT", response.data.message);
          this.$store.dispatch("loading/detriggerFunctionLoader", "delete");
        })
        .catch((error) => {
          console.error("Error Deleting Consultation Form: ", error);
          this.$store.commit(
            "alerts/SET_SHOW_ERROR",
            error.response.data.message
          );
          this.$store.dispatch("loading/detriggerFunctionLoader", "delete");
        });
    },

    // INDEX METHODS
    fetchConsultationFormForDoctor: function ({ commit }, consultation_id) {
      const url = `/doctors/consultations/${consultation_id}/consultation-forms`;
      return this.$axios
        .get(url)
        .then((response) => {
          const consultation_form = response.data.consultation_form;
          commit("SET_CONSULTATION_FORM_INDEX", consultation_form);
        })
        .catch((error) => {
          console.error("Error Fetching Consultation Form: ", error);
        });
    },
    fetchConsultationFormForAdmin: function ({ commit }, consultation_id) {
      const url = `admin/consultations/${consultation_id}/consultation-forms`;
      return this.$axios
        .get(url)
        .then((response) => {
          const consultation_form = response.data.consultation_form;
          commit("SET_CONSULTATION_FORM_INDEX", consultation_form);
        })
        .catch((error) => {
          console.error("Error Fetching Consultation Form: ", error);
        });
    },

    // SHOW METHODS
    showConsultationFormForDoctor: function (
      { commit },
      { consultation_id, consultation_form_id }
    ) {
      const url = `doctors/consultations/${consultation_id}/consultation-forms/${consultation_form_id}`;
      return this.$axios
        .get(url)
        .then((response) => {
          commit("SET_CONSULTATION_FORM", response.data.consultation_form);
        })
        .catch((error) => {
          console.error("Error Fetching Consultation Form: ", error);
        });
    },
    showConsultationFormForAdmin: function (
      { commit },
      { consultation_id, consultation_form_id }
    ) {
      const url = `admin/consultations/${consultation_id}/consultation-forms/${consultation_form_id}`;
      return this.$axios
        .get(url)
        .then((response) => {
          commit("SET_CONSULTATION_FORM", response.data.consultation_form);
        })
        .catch((error) => {
          console.error("Error Fetching Consultation Form: ", error);
        });
    },
  },
};
